.collaborate-message {
    // margin-right: -1px;
    border-left: 1px solid var(--gray-100);

    .ai-message-container {
        border: 1px solid var(--gray-100);
        background-color: white;
        position: relative;
        margin-bottom: 10px;
        height: auto;
        // overflow: hidden;
        border-radius: 8px;
        margin-left: 4px;

        .ai-message {
            position: relative;
            z-index: 2;
            background-color: white;
            min-height: 50px;
            padding: 6px 24px 6px 16px;
            border-left: none;
            // display: grid;
            // grid-template-columns: auto 120px;
            // align-items: center;

            &.with-control-buttons {
                // grid-template-columns: auto 485px;
            }

            .character-name {
                font-size: 14px;
                font-weight: bold;
            }

            .character-message,
            .message {
                font-size: 14px;
            }

            .character-message-file-link {
                color: var(--blue-800);

                &:hover {
                    color: var(--blue-900);
                    text-decoration: underline;
                }
            }

            .assign-select {
                height: 35px !important;
                width: 100%;
                font-size: 14px;
            }
        }

        .human-input-container {
            position: absolute;
            left: 0;
            top: 100%;
            right: 0;
            transform: translateY(-100%);

            &.page-entry {
                animation: collabInputEntry 0.5s ease-out forwards;
            }

            &.page-exit {
                animation: collabInputExit 0.5s ease-out forwards;
            }
        }
    }

    .user-message-container {
        padding: 10px 0 10px 25px;

        .user-message {
            border: 1px solid var(--gray-100);
            border-radius: 8px;
            padding: 14px;
            background-color: white;
            font-size: 14px;
            line-height: 140%;

            .character-name {
                font-size: 14px;
                font-weight: bold;
            }

            .character-message,
            .message {
                font-size: 14px;
            }
        }
    }
}

.input-message-form-container {
    .input-message-form {
        max-width: 350px;
        display: grid;
        grid-template-columns: auto 70px;
        align-items: center;
        grid-column-gap: 2px;
        margin-bottom: 10px;

        input {
            height: 35px;
            font-size: 14px;
            border: 1px solid var(--gray-100);
            border-radius: 4px;
            padding: 0 10px;
            width: 100%;
            min-width: 100px;
        }

        .actions {
            display: flex;
            gap: 4px;

            button {
                height: 30px;
                width: 30px;
                border-radius: 4px;
                background-color: var(--gray-50);
                transition: all 0.4s linear;
                display: flex;
                justify-content: center;
                align-items: center;

                &.save-btn {
                    &:hover {
                        background-color: var(--green-50);
                        color: var(--green-500);
                    }
                }

                &.cancel-btn {
                    &:hover {
                        background-color: var(--red-50);
                        color: var(--red-500);
                    }
                }
            }
        }
    }

    .input-message-text {
        display: grid;
        grid-template-columns: auto 50px;
        gap: 10px;

        p.text {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }

        .edit-btn {
            color: #2196f3;

            &:hover {
                text-decoration: underline;
            }
        }
    }
}

.auto-interaction-mdx {
    h3 {
        font-weight: 700;
        font-size: 15px;
        margin-top: 16px;
        margin-bottom: 0px;
        padding-bottom: 2px;
        // border-bottom: 1px solid #dddddd;
        //color: var(--blue-900);
    }
    code {
        background-color: var(--blue-50);
        color: var(--blue-800);
        border-radius: 6px;
        padding: 2px 4px;
        font-size: 12px;
    }
    p {
        border: none;
    }
    strong {
        font-weight: 600;
        border: none;
    }
    hr {
        border-color: 1px solid #dddddd;
        margin-bottom: 4px;
    }
}

.brain-activity-mdx {
    h3 {
        font-weight: 700;
        font-size: 14px;
        margin-top: 16px;
        margin-bottom: 0px;
        padding-bottom: 4px;
        // border-bottom: 1px solid #dddddd;
        //color: var(--blue-900);
    }
    p {
        border: none;
    }
    strong {
        border: none;
        font-weight: 600;
    }
    code {
        background-color: var(--blue-50);
        color: var(--blue-800);
        border-radius: 6px;
        padding: 2px 4px;
        font-size: 12px;
    }
}

@keyframes collabInputEntry {
    0% {
        display: none;
        opacity: 0;
        transform: translateY(-100%);
    }

    10% {
        display: grid;
    }

    100% {
        display: grid;
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes collabInputExit {
    0% {
        display: grid;
        opacity: 1;
        transform: translateY(0);
    }

    90% {
        display: none;
    }

    100% {
        opacity: 0;
        transform: translateY(-100%);
        display: none;
    }
}
