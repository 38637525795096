.ideation-view {
    height: 100%;
    display: grid;
    grid-template-rows: 55px auto 55px;

    &.without-search-box {
        grid-template-rows: 55px auto 40px;
    }

    .toolbar {
        height: 55px;
    }

    .search-view {
        height: 100%;
        overflow-y: auto;
    }

    .search-box-form {
        position: relative;
        height: 100%;
    }

    .persona-select {
        width: 160px;
    }
}
