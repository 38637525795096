$profile-image-size: 36px;
// Height of navbar
$top-bar-height: 47px;
$sidebar-width: 350px;

.arin-navbar {
    height: $top-bar-height;
    // border-bottom: 1px solid #ddd;
    background-color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 105px 0 24px;
    position: fixed;
    top: 4px;
    left: 8px;
    right: 8px;
    z-index: 10;
    border-radius: 8px;

    .navbar-leading {
        display: flex;
        align-items: center;

        .arin-logo {
            // height: 23px;
            height: 30px;
            width: auto;
        }

        .nav-bar-links {
            list-style: none;
            margin: 0;
            padding: 0 0 0 2rem;

            li {
                display: inline-block;
                margin-right: 30px;

                a {
                    border-radius: 6px;
                    position: relative;
                    color: #6e6e80;
                    cursor: pointer;
                    font-weight: 400;
                    padding: 8px 10px;
                    font-size: 15px;

                    &:hover:not(.active) {
                        color: #000000;
                        background-color: rgba(0, 0, 0, 0.05);
                    }

                    &.active {
                        color: #000000;

                        // border-bottom: 2px solid #000;
                        // background-color: lighten(#000000, 95%);
                        &::after {
                            content: "";
                            position: absolute;
                            left: 50%;
                            width: 40%;
                            height: 2px;
                            bottom: 2px;
                            background-color: var(--primary-base);
                            border-radius: 25px;
                            transform: translateX(-50%);
                        }
                    }
                }
            }
        }
    }

    .navbar-actions {
        // padding-top: 8px;

        .profile-info {
            display: flex;
            // align-items: center;
            cursor: pointer;
            gap: 0.7rem;

            .profile-image {
                height: $profile-image-size;
                width: $profile-image-size;
                border-radius: 100%;
            }

            .profile-description {
                margin-right: 0.4rem;

                .profile-name {
                    font-size: 16px;
                    font-weight: 400;
                    margin-right: 0.2rem;
                    line-height: 101%;
                    margin-bottom: 0.1rem;
                }

                .team-name {
                    color: #999;
                    font-size: 14px;
                    text-align: left;
                    line-height: 101%;
                }
            }
        }
    }
}

.arin-body {
    position: fixed;
    top: $top-bar-height;
    left: 0;
    bottom: 0;
    right: 0;
    overflow-y: auto;
}
