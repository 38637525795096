.ideation-container {
    overflow: hidden;
    margin-bottom: 10px;

    .ideation-card {
        position: relative;
        background-color: var(--gray-50);
        border: 1px solid var(--gray-100);
        border-radius: 8px;
        padding: 12px;
        cursor: pointer;
        height: auto;
        z-index: 2;

        &:hover {
            background-color: var(--gray-100);
        }

        .title-section {
            display: grid;
            grid-template-columns: auto 25%;
            align-items: center;
            // margin-bottom: 8px;

            .title {
                font-size: 15px;
                font-weight: 600;
                width: 100%;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            .action {
                display: flex;
                gap: 8px;
                justify-content: flex-end;
                .icon {
                    font-size: 18px;
                }
            }
        }

        .description {
            font-size: 14px;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            overflow: hidden;
            margin-bottom: 5px;
        }

        .ideation-card-action {
            .icon {
                font-size: 17px;
            }
        }
    }

    .files-section {
        padding: 8px 0 8px;
        transform: translateY(-500%);
        display: none;
        height: 0;

        &.show {
            transform: translateY(0);
            height: auto;
            display: block;
        }

        transition: transform 0.2s linear;

        .file-row {
            border-bottom: 1px solid var(--gray-100);
            padding: 8px 10px;
            //display: flex;
            //justify-content: space-between;
            border-radius: 8px;

            &.active {
                background-color: var(--blue-50);
                border: 1px solid var(--blue-500);
            }

            .file-name {
                font-weight: 500;
                font-size: 14px;
                display: flex;
                justify-content: space-between;
                margin-bottom: 5px;
            }
            .file-description {
                //     display: -webkit-box;
                //     -webkit-line-clamp: 3;
                //     -webkit-box-orient: vertical;
                //     overflow: hidden;
                margin-bottom: 5px;
            }
            .goals-list {
                margin-top: 8px;
                padding-top: 5px;
                margin-bottom: 12px;
                border-top: 1px solid var(--gray-100);
                .goal-title {
                    font-weight: bold;
                    font-size: 14px;
                    margin: 0;
                }
            }

            .actions {
                display: flex;
                justify-content: flex-end;
                button.btn {
                    font-size: 13px;
                    font-weight: 500;
                    padding: 6px;
                    color: var(--blue-600);

                    &.danger {
                        color: #e35555;
                    }
                }
                button {
                    .icon {
                        font-size: 15px;
                    }
                }
            }
        }
    }
}

.clone-loading-box {
    display: flex;
    height: 250px;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    img.icon {
        height: 50px;
        margin: auto;
    }
}
