.sidebar {
    background-color: white;
    border-right: 1px solid var(--gray-100);
    border-radius: 8px;
    .sidebar-header {
        height: 55px;
        border-bottom: 1px solid var(--gray-100);
        display: flex;
        justify-content: center;
        flex-direction: column;
        .title {
            font-size: 14px;
        }
        .subtitle {
            margin-top: 0px;
            font-size: 12px;
        }
    }

    .sidebar-body {
        height: calc(100% - 55px);
        .avatar-card {
            display: flex;
            justify-content: space-between;
            align-items: center;
            border: 1px solid var(--gray-100);
            border-radius: 8px;
            cursor: pointer;
            &.active {
                border-color: var(--blue-500);
                background: var(--blue-50);

                .clone-text {
                    color: var(--blue-700);
                }
            }
            .profile-image {
                height: 30px;
                width: 30px;
                border-radius: 100%;
            }
            .clone-text {
                font-size: 14px;
                font-weight: 500;
            }
            .clone-actions {
            }
        }

        .arin-react-select__multi-value {
            background-color: var(--blue-500);
            color: white;
        }
        .arin-react-select__multi-value__label {
            color: white;
        }
    }
}
