@tailwind base;
@tailwind components;
@tailwind utilities;
@import "styles/colors";
@import "styles/mixins/grid";

@layer base {
    :root {
        --background: 0 0% 100%;
        --foreground: 222.2 47.4% 11.2%;

        --muted: 210 40% 96.1%;
        --muted-foreground: 215.4 16.3% 46.9%;

        --popover: 0 0% 100%;
        --popover-foreground: 222.2 47.4% 11.2%;

        --border: 214.3 31.8% 91.4%;
        --input: 214.3 31.8% 91.4%;

        --card: 0 0% 100%;
        --card-foreground: 222.2 47.4% 11.2%;

        --primary: 222.2 47.4% 11.2%;
        --primary-foreground: 210 40% 98%;

        --secondary: 210 40% 96.1%;
        --secondary-foreground: 222.2 47.4% 11.2%;

        --accent: 210 40% 96.1%;
        --accent-foreground: 222.2 47.4% 11.2%;

        --destructive: 0 100% 50%;
        --destructive-foreground: 210 40% 98%;

        --ring: 215 20.2% 65.1%;

        --radius: 0.5rem;
    }

    .dark {
        --background: 224 71% 4%;
        --foreground: 213 31% 91%;

        --muted: 223 47% 11%;
        --muted-foreground: 215.4 16.3% 56.9%;

        --accent: 216 34% 17%;
        --accent-foreground: 210 40% 98%;

        --popover: 224 71% 4%;
        --popover-foreground: 215 20.2% 65.1%;

        --border: 216 34% 17%;
        --input: 216 34% 17%;

        --card: 224 71% 4%;
        --card-foreground: 213 31% 91%;

        --primary: 210 40% 98%;
        --primary-foreground: 222.2 47.4% 1.2%;

        --secondary: 222.2 47.4% 11.2%;
        --secondary-foreground: 210 40% 98%;

        --destructive: 0 63% 31%;
        --destructive-foreground: 210 40% 98%;

        --ring: 216 34% 17%;

        --radius: 0.5rem;
    }
}

@layer base {
    * {
        @apply border-border;
    }

    body {
        @apply bg-background text-foreground;
        font-feature-settings:
            "rlig" 1,
            "calt" 1;
    }
}

html,
body {
    height: 100% !important;
    width: 100% !important;
    overflow: hidden;
}

body {
    // background: linear-gradient(to bottom, #eef2fb, #f0f1fe, #ecf0fc) !important;
    background-color: var(--gray-50) !important;
    margin: 0;
    font-family: "Inter", sans-serif !important;
    font-size: 14px;
    color: #1e1e1e;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

#view {
    height: 100%;
    width: 100%;
    overflow-y: auto;
}

#root,
#App {
    height: 100%;
    width: 100%;
}

#loading-el {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: white;
    z-index: 999999999;

    img {
        height: 50px;
        position: absolute;
        top: 50%;
        left: 50%;
        right: 50%;
        bottom: 50%;
        transform: translate(-50%, -50%);
    }
}

$page-top-offset: 10px;
$toolbar-height: 55px;
$left-column-width: 330px;
$main-view-width: 950px;
$search-box-height: 135px;
$search-box-shrink-height: 73px;
$right-column-width: 350px;

$page-top-offset: 10px;
$toolbar-height: 55px;
$left-column-width: 330px;
$main-view-width: 950px;
$search-box-height: 135px;
$search-box-shrink-height: 73px;
$right-column-width: 350px;

.search-layout-wrapper,
.three-col-layout {
    height: 100%;
    overflow: hidden;
    display: grid;
    grid-template-columns: 250px auto 250px;
    grid-column-gap: 10px;

    @include media-custom-min(1000px) {
        grid-template-columns: 250px auto 250px;
    }

    @include media-custom-min(1250px) {
        grid-template-columns: 300px auto 300px;
    }

    @include media-custom-min(1500px) {
        grid-template-columns: 340px auto 350px;
    }

    .left-pane {
        padding-top: $page-top-offset;
        padding-bottom: $page-top-offset;
        padding-left: $page-top-offset;
    }

    .right-pane {
        padding-top: $page-top-offset;
        padding-right: $page-top-offset;
    }

    .grid-column {
        position: relative;
        height: 100%;
        overflow: hidden;
        padding-top: $page-top-offset;
        padding-bottom: $page-top-offset;

        .column-contents {
            background-color: white;
            height: 100%;
            border-radius: 8px;
            overflow-y: hidden;
            position: relative;
        }

        &.main {
            .search-view {
                height: calc(100% - (#{$search-box-height}));
                padding-bottom: 8px;
                border-bottom-right-radius: 8px;
                border-bottom-left-radius: 8px;

                &.with-toolbar {
                    height: calc(100% - (#{$search-box-height} + #{$toolbar-height}));

                    &.expand {
                        height: calc(100% - (#{$search-box-shrink-height} + #{$toolbar-height}));
                    }
                }

                &.expand {
                    height: calc(100% - (#{$search-box-shrink-height}));
                }

                .scroll-view {
                    height: 100%;
                    overflow-y: auto;
                    padding: 0 0 0 2px;
                    border-bottom-right-radius: 8px;
                    border-bottom-left-radius: 8px;

                    /* width */
                    &::-webkit-scrollbar {
                        width: 5px;
                        display: none;
                        border-radius: 8px;
                    }

                    /* Track */
                    &::-webkit-scrollbar-track {
                        background: var(--gray-100);
                    }

                    /* Handle */
                    &::-webkit-scrollbar-thumb {
                        background: #888;
                    }

                    /* Handle on hover */
                    &::-webkit-scrollbar-thumb:hover {
                        background: var(--gray-400);
                    }
                }
            }

            .search-box-form {
                min-height: $search-box-height;
                position: absolute;
                width: 100%;
                bottom: 0;
                left: 50%;
                transform: translateX(-50%);

                &.shrink {
                    min-height: $search-box-shrink-height;
                }
            }
        }

        &.chaining-col {
            padding: $page-top-offset 0;
        }
    }
}

.toolbar {
    height: $toolbar-height;
    width: 100%;
    display: flex;
    margin-left: 2px;
    align-items: center;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    border-bottom: 1px solid var(--gray-100);
    background-color: white;

    .twin-avatars {
        display: flex;

        img {
            height: 35px;
            border-radius: 100%;
            margin-left: -6px;
            outline: 3px solid white;

            &:first-child {
                margin-left: 0;
                border: none;
            }
        }
    }

    .arin-name {
        font-weight: bold;
    }
}

.side-pane-2-view {
    display: grid;
    grid-template-columns: $left-column-width auto;
    grid-column-gap: 10px;
    grid-template-rows: 100%;
    height: 100%;
    overflow: hidden;

    .left-pane {
        padding: 10px 0 10px 10px;
        height: 100%;
    }

    .main-pane {
        padding: 10px 10px 10px 0;
        position: relative;
        height: 100%;

        .main-pane-contents {
            background-color: white;
            border-radius: 8px;
            height: 100%;
            position: relative;
            overflow-x: hidden;

            &.no-bg {
                background-color: transparent;
            }

            .main-pane-body {
                position: relative;
                height: calc(100% - 55px);
                overflow-y: auto;
                padding: 24px;
            }
        }
    }
}

.sidebar-toolbar {
    height: 55px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-bottom: 1px solid var(--gray-100);

    .title {
        font-size: 15px;
        font-weight: bold;
        margin: 0;
    }

    .subtitle {
        font-size: 13px;
        margin: -1px 0 0;
    }
}

.side-nav-sm-container {
    border-bottom: 1px solid var(--gray-100);

    .side-nav-sm {
        border-bottom: none;
        height: 54px;
    }
}

.side-nav-sm {
    height: 55px;
    padding: 0 24px;
    border-bottom: 1px solid var(--gray-100);
    display: flex;
    flex-direction: column;
    justify-content: center;

    &.with-action {
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    }

    .title {
        font-size: 16px;
        font-weight: 500;
        line-height: 1;
        margin-bottom: 7px;

        .icon {
            display: inline-block;
            font-size: 15px;
            margin-right: 5px;
        }
    }

    .subtitle {
        font-size: 13px;
        line-height: 1;
        margin: 0;
        color: var(--gray-600);
    }
}

.side-nav-lg {
    height: 80px;
    padding: 0 24px;
    border-bottom: 1px solid var(--gray-100);
    display: flex;
    flex-direction: column;
    justify-content: center;

    .title {
        font-size: 15px;
        font-weight: bold;
        line-height: 1;
        margin-bottom: 5px;

        .icon {
            display: inline-block;
            font-size: 15px;
            margin-right: 5px;
        }
    }

    .subtitle {
        font-size: 13px;
        line-height: 1.5;
        margin: 0;
        color: var(--gray-600);
    }
}

.sidebar-sm {
    height: 100%;
    border-radius: 8px;
    background-color: white;

    .sidebar-body {
        position: relative;
        height: calc(100% - 55px);
        overflow-y: auto;
        padding: 12px 20px;
    }
}

.side-menu-card {
    display: grid;
    grid-template-columns: auto 16px;
    background-color: var(--gray-50);
    border: 1px solid;
    border-color: var(--gray-100);
    height: 40px;
    border-radius: 8px;
    cursor: pointer;
    margin-bottom: 16px;
    align-items: center;
    padding: 0 12px;

    .menu-leading {
        display: grid;
        grid-template-columns: 100%;
        align-items: center;
        column-gap: 10px;

        &.with-icon {
            grid-template-columns: 16px auto;
        }

        .menu-icon {
            font-size: 16px;
        }
    }

    .menu-name {
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        font-size: 15px;
        text-overflow: ellipsis;
    }

    &:hover {
        background-color: var(--gray-100);
    }

    &.focus {
        background-color: var(--blue-50);
        color: var(--blue-700);
        border-color: var(--blue-100);
    }
}

.action-card {
    background-color: var(--gray-50);
    border-radius: 8px;
    padding: 10px 12px;
    margin-bottom: 12px;
    cursor: pointer;
    border: 1px solid var(--gray-100);

    &.allow-hover {
        &:hover {
            background-color: var(--gray-100);
            border-color: var(--gray-100);
        }
    }

    .action-card-title {
        display: grid;
        grid-template-columns: auto 105px;
        align-items: flex-start;

        .title-text {
            white-space: nowrap;
            width: 100%;
            overflow: hidden;
            font-weight: bold;
            font-size: 14px;
            text-overflow: ellipsis;
        }

        .action {
            text-align: right;

            button {
                display: inline-flex;
                font-size: 13px;
                font-weight: 500;
                align-items: center;
                padding: 3px 4px;
                border-radius: 6px;
                color: var(--blue-600);

                &:hover {
                    background-color: rgba(0, 0, 0, 0.05);
                }

                .icon {
                    font-size: 18px;
                }
            }
        }
    }

    .action-card-subtitle {
        margin-top: -6px;
        font-size: 12px;
        margin-bottom: 4px;
    }

    .action-card-text {
        font-size: 13px;
        display: -webkit-box;
        //-webkit-line-clamp: attr(data-line-clamp) 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
    background: #fefefe;
}

a.link {
    color: var(--blue-500);

    &:hover {
        text-decoration: underline;
    }
}

.empty-state {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;

    .empty-content {
        width: 380px;
        text-align: center;

        .icon {
            height: 120px;
            margin: auto;
        }

        .title {
            margin-bottom: 1.5px;
        }
    }
}

*[contenteditable="true"]:empty:before {
    content: attr(placeholder);
    pointer-events: none;
    color: #999999;
    display: block;
    /* For Firefox */
}

.app_support-warning {
    // display: flex;
    display: none;
    justify-content: center;
    align-items: center;
    z-index: 9999;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: white;

    @include media-custom-min(1000px) {
        display: none;
    }

    .app_logo {
        width: 200px;
        margin: auto;
    }
}

.nomic-navbar {
    background-color: red !important;
}

.chakra-modal__content-container {
    height: 100% !important;
    bottom: 0 !important;
}

/**
CHAKRA TABS
 */
.chakra-tabs__tablist {
    // overflow-y: hidden !important;
    // overflow-x: auto !important;

    &::-webkit-scrollbar {
        display: none;
    }
}

/**
React select
 */
/* .arin-react-select-container {
  } */
.arin-react-select-container {
    .arin-react-select__control {
        background-color: white;
        border-color: #ececf1;

        &:hover {
            border-color: #dddddd;
        }
    }

    &.arin-react-select--is-disabled {
        .arin-react-select__control {
            background-color: var(--gray-50);
            border-color: #ececf1;

            &:hover {
                border-color: #dddddd;
            }
        }

        .arin-react-select__indicator-separator {
            background-color: var(--gray-50);
        }
    }
}

.arin-react-select-container .arin-react-select__control--is-focused {
    border-color: var(--primary-base);
    box-shadow: none;

    &:hover {
        border-color: var(--primary-base);
    }
}

.arin-react-select-container .arin-react-select__menu {
    background-color: white;
    border: 0 solid var(--gray-100);
}

.arin-react-select-container .arin-react-select__option {
    color: #6e6e80;
    background-color: white;

    &:hover {
        // color: var(--gray-50);
        background-color: var(--gray-100);
    }
}

/* .arin-react-select-container .arin-react-select__option--is-focused {
  @apply bg-gray-200 bg-gray-800;
} */

.arin-react-select-container .arin-react-select__indicator-separator {
    display: none;
}

.arin-react-select-container .arin-react-select__input-container,
.arin-react-select-container .arin-react-select__placeholder,
.arin-react-select-container .arin-react-select__single-value {
    color: var(--gray-700);
}

.arin-react-select-container {
    .arin-react-select__multi-value {
        background-color: var(--primary-base);
        border-radius: 4px;

        .arin-react-select__multi-value__label {
            color: white !important;
        }

        .arin-react-select__multi-value__remove {
            svg {
                fill: white !important;
            }
        }
    }
}

/**
===== custom modals ===
 */
.arin-modal-container {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1999;
    display: flex;
    justify-content: center;
    align-items: center;

    .arin-modal-content {
        background-color: white;
        border-radius: 8px;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
        padding: 16px 24px;
        width: 550px;

        &.sm {
            width: 425px;
        }

        &.lg {
            width: 860px;
        }

        .arin-modal-body {
            margin: 10px 0 25px;
        }

        .arin-modal-footer {
            display: flex;
            justify-content: flex-end;
            gap: 10px;
        }
    }
}

::-webkit-scrollbar {
    width: 5px;
    border-radius: 8px;
}

/* Track */
::-webkit-scrollbar-track {
    background: var(--gray-100);
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: var(--gray-400);
}

.prompt-engineer {
    width: 100%;
    height: calc(100vh - 55px);
}

.chakra-form__label,
.label {
    font-size: 15px !important;
}

/**
 * Three dots loading animation
**/
.pending-cursor {
    //display: inline-block;
    //height: 15px;
    //margin-left: 2px;
    //width: 8px;
    //background: #646464;
    display: flex;
    gap: 4px;
    align-items: baseline;

    .loading-dots {
        display: flex;
        gap: 1.5px;

        .dot {
            display: inline-block;
            height: 4px;
            width: 4px;
            background: black;
            border-radius: 100%;
            animation: blink 1.5s infinite;

            &:nth-child(2) {
                animation-delay: 0.5s;
            }

            &:nth-child(3) {
                animation-delay: 1s;
            }
        }
    }
}

@keyframes blink {
    0%,
    100% {
        opacity: 0.2;
    }

    50% {
        opacity: 1;
    }
}

.persona-avatar-card {
    border: 1px solid var(--gray-100);
    border-radius: 8px;
    position: relative;
    cursor: pointer;
    position: relative;

    &:hover {
        .actions-container {
            display: block;
        }
    }

    .actions-container {
        display: none;
        position: absolute;
        right: 0;
        top: 10px;
        background-color: white;
        padding: 2px 4px 2px 8px;
    }

    &.active {
        border-color: var(--blue-500);
        background: var(--blue-50);

        .clone-text {
            color: var(--blue-700);
        }
    }

    .profile-image {
        height: 30px;
        width: 30px;
        border-radius: 100%;
    }

    .dropdown-portal {
        position: absolute;
        right: 0;
        top: 0;
        width: 45%;
        min-height: 100px;
        background-color: white;
        border-radius: 8px;
    }
}

/*
The StripeElement class is applied to the Element container by default.
More info: https://stripe.com/docs/stripe-js/reference#element-options
*/

.StripeElement,
.StripeElementIdeal,
.StripeElementP24,
.StripeElementEPS,
.StripeElementFpx {
    display: block;
    // margin: 0 0 20px 0;
    max-width: 500px;
    padding: 10px 14px;
    border-radius: 8px;
    background: white;
    border: 1px solid #d9d9d9;
}

.StripeElement--focus,
.StripeElementIdeal--focus,
.StripeElementP24--focus,
.StripeElementEPS--focus,
.StripeElementFpx--focus {
    border-color: #2b2b2b;
    box-shadow: 0 0 0 1px rgba(52, 106, 254, 0.64);
    border-inline-end-width: 1px;
    outline: 0;
    -webkit-transition: all 150ms ease;
    transition: all 150ms ease;
}

.StripeElement--invalid {
    border-color: var(--red-500);
    box-shadow: 0 0 0 1px rgba(255, 73, 73, 0.64);
    border-inline-end-width: 1px;
    outline: 0;
    -webkit-transition: all 150ms ease;
    transition: all 150ms ease;
}

.StripeElement.loading {
    height: 41.6px;
    opacity: 0.6;
}

.StripeElementIdeal,
.StripeElementP24,
.StripeElementEPS,
.StripeElementFpx {
    padding: 0;
}

.overflow-2-lines {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}
