.neural-path-ways {
    background-color: var(--gray-50);
    display: grid;
    grid-template-columns: auto 350px;
    grid-column-gap: 10px;

    .left-col,
    .right-col {
        height: 100%;
        border-radius: 8px;
        background-color: white;

        .right-col-content {
            border-bottom: 1px solid var(--gray-100);
            overflow-y: auto;

            .argument-values {
                transition:
                    opacity 0.5s ease,
                    max-height 0.5s ease;
                max-height: 0;
                overflow: hidden;
                opacity: 0;

                &.show-arguments {
                    opacity: 1;
                    max-height: 1000px;
                }
            }
        }
    }

    .right-col {
        overflow: hidden;
    }
}
